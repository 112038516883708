<template>
  <div class="third-section">
    <div class="third-section-info">
      <div class="left-info">
        <img
          v-motion
          src="/img/saas1.png"
          class="forMobile"
          alt=""
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        />
        <h2>Get launched in 1 day with our no-code solution.</h2>
        <h4>
          Don't want to allocate any dev resources for billing? No worries, use
          HelixPay's Subscription Storefront with customized branding and
          billing options.
        </h4>

        <div class="left-info-ul">
          <li class="first-list-item">
            <p>No Coding required.</p>
          </li>
          <li class="first-list-item">
            <p>Customized design for your branding.</p>
          </li>
          <li class="first-list-item">
            <p>
              Select subscription "SKU" details and pricing for your business.
            </p>
          </li>
          <li class="first-list-item">
            <p>HelixPay Checkout with all popular payment methods.</p>
          </li>
          <li class="first-list-item">
            <p>
              Helixpay business tools & analytics gives you powerful view of
              your recurring revenues.
            </p>
          </li>
        </div>

        <img
          v-motion
          src="/img/saas1.png"
          class="forWeb"
          alt=""
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        />
      </div>

      <div class="right-info">
        <img
          v-motion
          src="/img/saas2.png"
          class="forMobile"
          alt=""
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        />
        <h2>Full integration with our API integration.</h2>
        <h4>
          Clear API documentation with complete customization for any type of
          recurring payment system.
        </h4>
        <h4>
          Check out our API docs
          <a
            href="https://helixpay.readme.io/reference/introduction"
            style="color: #25a4e1; text-decoration: none; font-weight: 900"
          >
            here.
          </a>
        </h4>

        <div class="right-info-ul">
          <li class="first-list-item">
            <p>
              Integrate HelixPay Subscription Checkout directly into your
              website.
            </p>
          </li>
          <li class="first-list-item">
            <p>
              Set the subscription billing details via API and HelixPay handles
              the recurring payments.
            </p>
          </li>
          <li class="first-list-item">
            <p>HelixPay CheckOut with all popular payment methods.</p>
          </li>
          <li class="first-list-item">
            <p>
              Use HelixPay business tools & analytics or pull status updates
              into your existing dashboards with our webhooks.
            </p>
          </li>
        </div>

        <img
          v-motion
          src="/img/saas2.png"
          class="forWeb"
          alt=""
          :initial="{
            opacity: 0,
            y: 100,
          }"
          :visibleOnce="{
            opacity: 1,
            y: 0,
            transition: {
              delay: 100,
              stiffness: 200,
              damping: 90,
              mass: 3,
            },
          }"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.forMobile {
  display: none;
}

.third-section {
  max-width: 100%;
  align-items: center;
  margin: auto;
  background-color: black;
}

.third-section-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  width: 71rem;
  padding: 3rem 0;
  margin: auto;
  line-height: 1.5;
}

.third-section-info h2 {
  color: #25a4e1;
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 4rem;
  margin-bottom: 3rem;
  height: inherit;
  line-height: 1.5;
}

.third-section-info h4 {
  font-size: 1rem;
  font-weight: 500;
  color: white;
  line-height: 2;
}

.third-section-info p {
  font-weight: 500;
  opacity: 0.6;
  line-height: 2;
}

.left-info-ul {
  margin-top: 6rem;
}

.right-info h4 {
  margin-top: 4.7rem;
}

.right-info h4:nth-child(3) {
  margin-top: 1rem;
}

.first-list-item {
  list-style-type: disc;
  list-style-position: outside;
  padding: 20px 0 10px 20px;
}

.left-info-ul li {
  font-size: 1rem;
  color: rgb(194, 194, 194);
  line-height: 1.5;
  margin-bottom: 20px;
  margin-right: 30px;
  padding: 0px 20px 0px 40px;
  list-style: none;
  background-image: url('/img/check-list.svg');
  background-repeat: no-repeat;
  background-position: 0% 30%;
  background-size: 1rem;
}

.left-info img {
  width: 80%;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.right-info-ul li:nth-child(1) {
  margin-top: 3.5rem;
}

.right-info-ul li {
  font-size: 1rem;
  color: rgb(194, 194, 194);
  line-height: 1.5;
  margin-bottom: 20px;
  margin-right: 30px;
  padding: 0px 20px 0px 40px;
  list-style: none;
  background-image: url('/img/check-list.svg');
  background-repeat: no-repeat;
  background-position: 0% 13%;
  background-size: 1rem;
}

.right-info img {
  width: 80%;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

@media only screen and (max-width: 1400px) {
  .right-info h4 {
    margin-top: 0;
  }

  .left-info-ul {
    margin-top: 4.5rem;
  }

  .third-section-info {
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    width: 60rem;
  }
}

@media only screen and (max-width: 1050px) {
  .forMobile {
    display: block;
  }

  .forWeb {
    display: none;
  }

  .third-section-info {
    grid-template-columns: 1fr;
    width: 40rem;
  }

  .left-info-ul {
    padding-top: 2rem;
  }

  .left-info img,
  .right-info img {
    width: 100%;
  }
}

@media only screen and (max-width: 650px) {
  .third-section-info {
    grid-template-columns: 1fr;
    width: 90%;
  }

  .left-info-ul {
    margin-top: 1rem;
  }
}
</style>
